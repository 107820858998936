<template>
    <form v-if="form" class="w-full pb-3 pt-6 flex items-end">

        <div class="grid grid-cols-12 gap-6 w-full">
            <div class="col-span-12 lg:col-span-6 flex items-center space-x-2 md:space-x-10">
                <div class="col-span-1 flex items-center">
                    <Checkbox text="Uzkaite ar unikāliem kodiem" v-model="form.use_unique_codes" />
                </div>

                <div class="col-span-1 flex items-center">
                    <Checkbox text="Reversais PVN" v-model="form.reverse_vat" />
                </div>
            </div>

            <div class="col-span-12 lg:col-span-6 flex justify-end items-end">
                <div class=" flex justify-end items-end">
                    <button type="button" class="bg-white dark:bg-gray-750 py-2 px-4 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 mr-2" @click="close">
                        Atcelt
                    </button>

                    <template v-if="!loading">
                        <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
                            Saglabāt
                        </button>
                    </template>
                    <template v-else>
                        <Loading />
                    </template>
                </div>

            </div>
        </div>

    </form>
</template>

<script>
import {mapGetters} from "vuex";

import Validation from "@/modules/Validation";
import Checkbox from "@/components/Components/Checkbox";
import Loading from "@/components/Components/Loading";

export default {
    name: "EditCategoryOptions",
    components: {
        Checkbox,
        Loading,
    },
    props: ["category"],
    data() {
        return {
            form: null,
        }
    },
    mounted() {
        this.form = this.category
    },
    computed: {
        ...mapGetters({
            loading: "loading",
            errors: "errors",
        }),
        formValidation() {
            return {}
        }
    },
    methods: {
        close() {
            this.$store.dispatch("removeAllFormsForDisplay");
        },
        submit() {
            this.$Progress.start();

            if (Validation(this.formValidation, this.form)) {
                this.$store.dispatch('updateCategoryOptions', {
                    id: this.category.id,
                    data: {
                        use_unique_codes : this.form.use_unique_codes,
                        reverse_vat : this.form.reverse_vat,
                        name : this.category.name,
                    }
                })
                    .then(response => {
                        this.$Progress.finish()
                        this.close()
                    })
                    .catch(error => {
                        this.$Progress.fail()
                    })
            } else {
                this.$Progress.fail();
            }
        },
    }
}
</script>

<style scoped>

</style>